import {sendVKAnalyticsEvent} from "./vk";
import {reachYMGoal} from "./ym";

export const useAddToCartGoal = () => {
    document.querySelectorAll('[data-src="#buy-ticket"]').forEach((el) => {
        el.addEventListener('click', () => {
            sendVKAnalyticsEvent('addtocart');
            reachYMGoal('AddToCart');
        })
    })
};
