import {addMinutes, differenceInMinutes, format, fromUnixTime, parse} from "date-fns";
import {ru} from "date-fns/locale";

const normalizeDate = (date) => addMinutes(date, date.getTimezoneOffset());
export const getDateByTimestamp = (ts) => normalizeDate(fromUnixTime(ts));
export const timestampToDateKey = (ts) => format(getDateByTimestamp(ts), 'd.L.y', {locale: ru});
export const dateFromKey = (key) => parse(key, 'd.L.y', new Date());
export const timestampToDate = (ts) => format(getDateByTimestamp(ts), 'dd/LL/y', {locale: ru});
export const timestampToTime = (ts) => format(getDateByTimestamp(ts), 'HH:mm', {locale: ru});
export const dateToDateKey = (date, normalize = true) => format(normalize ? normalizeDate(date) : date, 'd.L.y', {locale: ru});
export const formatDate = (date) => format(date, 'dd/LL/y', {locale: ru});
export const formatDateIntoCRS = (date) => format(date, 'dd.LL.y', {locale: ru});
export const formatMonthDay = (date) => format(date, 'd MMMM', {locale: ru});
export const getMinutesDiff = (left, right) => differenceInMinutes(left, right);
export const dateFromuserInput = (dateStr) => parse(dateStr, 'd/MM/yyyy', new Date());
