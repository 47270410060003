import {apiFetchHandler} from "@/shared/api-fetch.handler";

//Обращется к источнику ссылки на оплату заказа, возвращает ссылку на оплату
export const acquiringUrlGet = async (urlSrc) => {
    const data = await apiFetchHandler(
        fetch(
            `${urlSrc}?success_url=${location.origin}/?payment=success&failed_url=${location.origin}/?payment=failed`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
    );
    return data && data['url'] ? data['url'] : null;
};
