const MetrikaSentEvents = [];

export function reachYMGoal(event, params = {}) {
    if (import.meta.env.PROD && window['ym']) {
        if (!MetrikaSentEvents.includes(event)) {
            console.info('Sending', event, 'event to Metrika');

            MetrikaSentEvents.push(event);
            ym(79845847, 'reachGoal', event, params);
        }
    }
}
