export const apiFetchHandler = (cb) => Promise
    .resolve(cb)
    .then(async (response) => {
        const responsePayload = await response.json();
        if (!response.ok) {
            let error = null;
            if (
                responsePayload &&
                responsePayload['error'] &&
                responsePayload['error']['error_msg'] &&
                responsePayload['error']['error_msg'] !== 'Bar Request'
            ) {
                error = responsePayload['error']['error_msg'];
            }
            if (!error && responsePayload && responsePayload[0]['errors']) {
                error = responsePayload
                    .map((item) => Object.entries(item.errors)
                        .map(([, error]) => error.join('; '))
                        .join('; '))
                    .join('; ');
            }
            if (!error) {
                error = 'Ошибка отправки запроса. Повторите попытку позже.';
            }
            throw new Error(error, {
                cause: responsePayload
            });
        }
        return responsePayload;
    });
