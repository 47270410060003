import {apiRequest} from "@/shared/api.request";

const tariffListToMap = (tariffList = []) => tariffList
    .reduce(
        (result, current) => {
            result.set(current.tariff_id, current);
            return result;
        },
        new Map()
    );
export const tariffsGet = async (cruiseId) => {
    const result = await apiRequest(`/cruises/${cruiseId}/price-table`);

    const metaTariffs = result.meta_tariffs.reduce((res, current) => {
        res[current['id']] = current;
        return res;
    }, {});
    let deckRoomClasses = [];
    const deckTarrifs = result.decks.reduce((result, deck) => {
        deckRoomClasses = deckRoomClasses.concat(deck.room_classes);
        result.push(
            deck.room_classes.reduce(
                (result, room_class) => result.concat(
                    room_class.accommodations.reduce((result, accommodation) =>
                        result.concat(
                            accommodation.price.reduce((result, {meta_tariff_id, tariff_id, value}) => {
                                const opt = {
                                    accommodationName: accommodation.name,
                                    metaTariffName: metaTariffs[meta_tariff_id]['name'],
                                    metaTariffType: metaTariffs[meta_tariff_id]['type'],
                                    metaTariffSort: metaTariffs[meta_tariff_id]['sort'],
                                    roomClassName: room_class.name,
                                    label: `${room_class.name} ${metaTariffs[meta_tariff_id]['name']}`,
                                    tariff_id,
                                    availableSeats: room_class.available_rooms,
                                    roomClass_id: room_class.id,
                                    roomClass_sort: room_class.sort,
                                    deck_id: deck.id,
                                    metaTariff_id: meta_tariff_id,
                                    price: value / 100
                                };
                                result.push(opt);
                                return result;
                            }, [])
                        ), [])
                ), []
            )
        );

        return result.map((result) => result.sort((tariff1, tariff2) =>  {
            if (tariff1['roomClass_sort'] !== tariff2['roomClass_sort']) {
                return tariff1['roomClass_sort'] - tariff2['roomClass_sort'];
            }
            return tariff1['metaTariffSort'] - tariff2['metaTariffSort'];
        }));
    }, []);
    const [deckTariffs, deck] = [tariffListToMap(deckTarrifs[0]), result.decks[0]];
    return [
        deck,
        deckTariffs,
        deckRoomClasses
    ]
};
