import {useSignal} from "@/shared/useSignal";
import {useActionModal} from "@/shared/ui/action-modal";
import {motorshipsGet} from "@/api/motorships.get";

const indexFactory = (cruisesSrc) => {
    const defaultVal = new Map();
    return cruisesSrc.reduce(
        (res, current) => {
            res.set(current.id, current);
            return res;
        },
        defaultVal
    );
};

export const createMotorshipsStore = () => {
    const [state, effect] = useSignal();
    const getMotorships = () => Array.from(state.value);
    const getNameById = (key) => key ? state.value.get(key).name ?? '' : '';
    const getAliasById = (key) => key ? state.value.get(key).alias ?? '' : '';
    const downloadMotorships = async () => {
        try {
            const motorships = await motorshipsGet();
            state.value = indexFactory(motorships);
        } catch (e) {
            console.error(e);
            useActionModal({
                heading: 'Ошибка сети',
                text: 'Не удалось загрузить список кораблей, пожалуйста повторите позже',
            });
        }
    };

    return {
        getMotorships,
        getNameById,
        getAliasById,
        downloadMotorships,
        useEffect: effect,
    };
};

export const motorshipsStore = createMotorshipsStore();
