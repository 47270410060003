import Swiper from "swiper/bundle";
import LazyLoad from "vanilla-lazyload";
import './styles.css';

export function createGallerySlider() {
    new Swiper('.gallery-swiper', {
        navigation: {
            nextEl: '.gallery-button-next',
            prevEl: '.gallery-button-prev'
        },
        scrollbar: {
            el: '.gallery-scrollbar'
        },
        pagination: {
            el: '.gallery-pagination',
            type: 'fraction'
        },
        speed: 1500,
        on: {
            afterInit: (swiper) => {
                new LazyLoad({
                    thresholds: '100%',
                    container: swiper.el,
                    unobserve_entered: true
                });
            }
        }
    });
}
