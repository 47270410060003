import Pristine from 'pristinejs';

const LATINIC_REG = /[^a-z]/i;
const CIRILIC_REG = /[^а-яё]/i;

Pristine.addValidator(
    'inputmask-datetime',
    function (value) {
        let result = false;
        if (this.inputmask.isComplete()) {
            const dateString = value.split('/').map((val) => parseInt(val));
            const dateSrc = new Date(dateString[2],dateString[1]-1,dateString[0]);
            result =
                dateSrc.getDate() === dateString[0] &&
                dateSrc.getMonth() + 1 === dateString[1] &&
                dateSrc.getFullYear() === dateString[2];
        }
        return result;
    },
    'Введите корректную дату'
);

Pristine.addValidator(
    'cirilic',
    function (value) {
        const element = this;
        const labels = element.closest('.form-popup__group-grid-row').getElementsByTagName('label');
        let values = [];
        let latinCheck = [];
        let cirilicCheck = [];
        let totalLatin = false;
        let totalCirilic = false;
        for (const label of labels) {
            let input = label.getElementsByTagName('input')[0];
            if (input.hasAttribute("data-pristine-cirilic")) {
                values.push(input.value);
            }
        }
        for (const value of values) {
            if (value) {
                latinCheck.push(LATINIC_REG.test(value));
                cirilicCheck.push(CIRILIC_REG.test(value));
            }
        }
        for (const latin of latinCheck) {
            if (latin) {
                totalLatin = latin;
            }
        }
        for (const cirilic of cirilicCheck) {
            if (cirilic) {
                totalCirilic = cirilic;
            }
        }
        if ((latinCheck.length > 0 || cirilicCheck.length > 0) && !(totalLatin && totalCirilic)) {
            for (const label of labels) {
                let input = label.getElementsByTagName('input')[0];
                if (input !== element && input.value !== '') {
                    if ('errors' in input.pristine && input.pristine.errors.length > 0) {
                        input.pristine.self.reset();
                    }
                }
            }
        }
        return !(LATINIC_REG.test(value) && CIRILIC_REG.test(value)) && !(totalLatin && totalCirilic);
    },
    'Необходимо использовать единый алфавит и доступны только буквы',
    5,
    true
);

export const usePristine = (...args) => new Pristine(...args);
