const nationalityList = {
    "1":	"Россия",
    "2":	"Азербайджан",
    "3":	"Армения",
    "4":	"Беларусь",
    "5":	"Грузия",
    "6":	"Казахстан",
    "7":	"Киргизия",
    "8":	"Латвия",
    "9":	"Литва",
    "10":	"Молдавия",
    "11":	"Таджикистан",
    "12":	"Туркменистан",
    "13":	"Узбекистан",
    "14":	"Украина",
    "15":	"Эстония",
    "16":	"Австралия",
    "17":	"Австрия",
    "18":	"Албания",
    "19":	"Алжир",
    "20":	"Ангола",
    "21":	"Арабские Эмираты",
    "22":	"Аргентина",
    "23":	"Аруба",
    "24":	"Афганистан",
    "25":	"Багамские острова",
    "26":	"Бангладеш",
    "27":	"Барбадос",
    "28":	"Бельгия",
    "29":	"Бенин",
    "30":	"Бермудские острова",
    "31":	"Болгария",
    "32":	"Боливия",
    "33":	"Босния и Герцеговина",
    "34":	"Бразилия",
    "35":	"Бруней",
    "36":	"Великобритания",
    "37":	"Венгрия",
    "38":	"Венесуэлла",
    "39":	"Вьетнам",
    "40":	"Гаити",
    "41":	"Гамбия",
    "42":	"Гондурас",
    "43":	"Гваделупа",
    "44":	"Гватемала",
    "45":	"Гвинея",
    "46":	"Германия",
    "47":	"Гибралтар",
    "48":	"Нидерланды",
    "49":	"Гонконг",
    "50":	"Гренада",
    "51":	"Гренландия",
    "52":	"Греция",
    "53":	"Гуана",
    "54":	"Дания",
    "55":	"Доминиканская Республика",
    "56":	"Египет",
    "57":	"Демократическая республика Конго",
    "58":	"Замбия",
    "59":	"Зимбабве",
    "60":	"Израиль",
    "61":	"Индия",
    "62":	"Индонезия",
    "63":	"Иордания",
    "64":	"Ирак",
    "65":	"Иран",
    "66":	"Ирландия",
    "67":	"Исландия",
    "68":	"Испания",
    "69":	"Италия",
    "70":	"Йемен",
    "71":	"Каймановы острова",
    "72":	"Камерун",
    "73":	"Канада",
    "74":	"Кения",
    "75":	"Кипр",
    "76":	"Китай",
    "77":	"Колумбия",
    "78":	"Камбоджа",
    "79":	"Конго",
    "80":	"Корея (Южная)",
    "81":	"Коста Рика",
    "82":	"Куба",
    "83":	"Кувейт",
    "84":	"Либерия",
    "85":	"Лихтенштейн",
    "86":	"Люксембург",
    "87":	"Мавритания",
    "88":	"Мадагаскар",
    "89":	"Македония",
    "90":	"Малайзия",
    "91":	"Мали",
    "92":	"Мальта",
    "93":	"Мексика",
    "94":	"Мозамбик",
    "95":	"Монако",
    "96":	"Монголия",
    "97":	"Морокко",
    "98":	"Намибия",
    "99":	"Непал",
    "100":	"Нигерия",
    "102":	"Никарагуа",
    "103":	"Новая Зеландия",
    "104":	"Норвегия",
    "105":	"Пакистан",
    "106":	"Панама",
    "107":	"Папуа Новая Гвинея",
    "108":	"Парагвай",
    "109":	"Перу",
    "110":	"Польша",
    "111":	"Португалия",
    "112":	"Пуэрто Рико",
    "113":	"Румыния",
    "114":	"Саудовская Аравия",
    "115":	"Сенегал",
    "116":	"Сингапур",
    "117":	"Сирия",
    "118":	"Словакия",
    "119":	"Словения",
    "120":	"Сомали",
    "121":	"Судан",
    "122":	"США",
    "123":	"Тайвань",
    "124":	"Таиланд",
    "125":	"Тринидад и Тобаго",
    "126":	"Тунис",
    "127":	"Турция",
    "128":	"Уганда",
    "129":	"Уругвай",
    "130":	"Филиппины",
    "131":	"Финляндия",
    "132":	"Франция",
    "133":	"Чад",
    "134":	"Чехия",
    "135":	"Чили",
    "136":	"Швейцария",
    "137":	"Швеция",
    "138":	"Шри-Ланка",
    "139":	"Эквадор",
    "140":	"Эфиопия",
    "141":	"ЮАР",
    "142":	"Сербия",
    "143":	"Ямайка",
    "144":	"Япония",
    "145":	"Бахрейн",
    "146":	"Андорра",
    "147":	"Белиз",
    "148":	"Бутан",
    "149":	"Ботсвана",
    "150":	"Буркина Фасо",
    "151":	"Бурунди",
    "152":	"Центральноафриканская Республика",
    "153":	"Коморос",
    "154":	"Кот-Д`ивуар",
    "155":	"Джибути",
    "156":	"Восточный Тимор",
    "157":	"Эль Сальвадор",
    "158":	"Экваториальная Гвинея",
    "159":	"Эритрея",
    "160":	"Фижи",
    "161":	"Габон",
    "162":	"Гана",
    "163":	"Гвинея-Биссау",
    "164":	"Корея (Северная)",
    "165":	"Ливан",
    "166":	"Лесото",
    "167":	"Ливия",
    "168":	"Мальдивы",
    "169":	"Маршалские острова",
    "170":	"Нигер",
    "171":	"Оман",
    "172":	"Катар",
    "173":	"Руанда",
    "174":	"Самоа",
    "175":	"Сейшеллы",
    "176":	"Сьерра-Леоне",
    "177":	"Суринам",
    "178":	"Свазиленд",
    "179":	"Танзания",
    "180":	"Западная Сахара",
    "181":	"Хорватия",
    "182":	"Ангилья",
    "183":	"Антарктида",
    "184":	"Антигуа и Барбуда",
    "185":	"Остров Буве",
    "186":	"Британские территории в Индийском Океане",
    "187":	"Британские Виргинские острова",
    "188":	"Мьянма",
    "189":	"Кабо-Верде",
    "190":	"Остров Рождества",
    "191":	"Кокосовые острова",
    "192":	"Острова Кука",
    "193":	"Доминика",
    "194":	"Фолклендские острова",
    "195":	"Фарерские острова",
    "196":	"Гвиана",
    "197":	"Французская Полинезия",
    "198":	"Южные Французские территории",
    "199":	"Острова Херд и Макдоналд",
    "200":	"Кирибати",
    "201":	"Лаос",
    "202":	"Макао",
    "203":	"Малави",
    "204":	"Мартиника",
    "205":	"Маврикий",
    "206":	"Майотта",
    "207":	"Микронезия",
    "208":	"Монтсеррат",
    "209":	"Науру",
    "210":	"Антильские острова",
    "211":	"Новая Каледония",
    "212":	"Ниуэ",
    "213":	"Остров Норфолк",
    "214":	"Палау",
    "215":	"Палестина",
    "216":	"Остров Питкэрн",
    "217":	"Реюньон",
    "218":	"Остров Св.Елены",
    "219":	"Острова Сент-Киттс и Невис",
    "220":	"Санта-Лючия",
    "221":	"Острова Сен-Пьер и Микелон",
    "222":	"Сент-Винсент и Гренадины",
    "223":	"Сан-Марино",
    "224":	"Соломоновы острова",
    "225":	"Южная Георгия и Южные Сандвичевы острова",
    "226":	"Острова Шпицберген и Ян-Майен",
    "227":	"Того",
    "228":	"Токелау",
    "229":	"Тонга",
    "230":	"Острова Тёркс и Кайкос",
    "231":	"Тувалу",
    "232":	"Американские Виргинские острова",
    "233":	"Вануату",
    "234":	"Ватикан",
    "235":	"Острова Уоллис и Футуна",
    "236":	"Черногория"
};
const createNationalityStore = () => {
    return {
        options: Object
            .entries(nationalityList)
            .map(
                ([value, text]) => ({value, text})
            ),
        getByCode(code){
            return nationalityList[code] ?? null;
        }
    };
};
export const nationalityStore = createNationalityStore();
