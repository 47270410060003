import {useVKAnalytics} from "./use";
import {isProd} from "@/shared/isProd";
const VKAnalyticsSentEvents = [];
export function sendVKAnalyticsEvent(event) {
    if(isProd()) {
        if (!VKAnalyticsSentEvents.includes(event)) {
            console.info('Sending', event, 'event to VK');

            useVKAnalytics(function () {
                VK.Retargeting.Event(event);
                VKAnalyticsSentEvents.push(event);
            });
        }
    }
}
