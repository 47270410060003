import {apiFetchHandler} from "@/shared/api-fetch.handler";

export const apiRequest = (uri = '', method = "GET", payload = undefined) =>
    apiFetchHandler(
        fetch(
            new URL(`/kda${uri}`, import.meta.env.VITE_API_BASE_URL),
            {
                method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: payload ? JSON.stringify(payload) : undefined
            }
        )
    );
