const createSexStore = () => {
    const options = [
        {
            value: 1,
            text: 'Мужской'
        },
        {
            value: 0,
            text: 'Женский'
        }
    ];
    return {
        options,
        getById:(id) => {
            const ID = parseInt(id);
            const res = options.filter((doc) => doc.value === ID);
            let result = null;
            if(res.length > 0){
                result = res[0];
            }
            return result;
        }
    }
};
export const sexStore = createSexStore();
