import './tariff.css'
import {useSignal} from "@/shared/useSignal";
import {formatDate, formatDateIntoCRS, timestampToDate, timestampToTime} from "@/shared/air-datepicker";
import {tariffsGet} from "@/api/tariffs.get";
import {documentStore} from "@/entity/order/store/document.store";
import {sexStore} from "@/entity/order/store/sex.store";
import {nationalityStore} from "@/entity/order/store/nationality.store";
import {deckSchemeGet} from "@/api/deck-scheme.get";
import {bookingRoomsGet} from "@/api/booking-rooms.get";
import {orderPost} from "@/api/order.post";
import {orderRoomPlacesGet} from "@/api/order-room-places.get";
import {passengersPut} from "@/api/passengers.put";
import {acquiringGatewayPut} from "@/api/acquiring-gateway.put";
import {acquiringUrlGet} from "@/api/acquiring-url.get";
import {createActionModal} from "@/shared/ui/action-modal";
import {ordersFormalizeGet} from "@/api/orders-formalize.get";
import {ordersCustomerPatch} from "@/api/orders-customer.patch";
import {passengerTypeStore} from "@/entity/order/store/passenger-type.store";
import {orderDelete} from "@/api/order.delete";


export const createOrderStore = () => {
    const [dateState, useDate] = useSignal();
    const [directionState, useDirection] = useSignal();
    const [cruiseState, useCruise] = useSignal();
    const [passengerListState, usePassengerList] = useSignal(new Map());
    const [passengerCount, usePassengerCount] = useSignal(1);
    const [orderContacts, useOrderContacts] = useSignal({fullname: null, email: null, phone: null});
    const [tariffListState, useTariffList] = useSignal([]);
    const [roomClasses] = useSignal([]);
    const [deckState, useDeck] = useSignal(null);
    const [seatMap] = useSignal(new Map());

    const getArrivalTime = () => directionState.value?.date_end_at_timestamp ?
        timestampToTime(directionState.value?.date_end_at_timestamp) : '';

    const downloadCruiseTariffs = async (id) => {
        if (id) {
            const [deck, deckTariffOpts, deckRoomClasses] = await tariffsGet(id);
            if (deckTariffOpts.size === 0) {
                createActionModal({
                    heading: "Уведомление",
                    text: 'Не удалось найти тарифы для круиза. Пожалуйста, выберите другую дату или направление.'
                });
            }
            tariffListState.value = deckTariffOpts;
            roomClasses.value = deckRoomClasses;
            if (deck && deck?.scheme) {
                const [svgScheme, rooms] = await Promise.all([
                    deckSchemeGet(deck.scheme),
                    bookingRoomsGet(id)
                ]);
                seatMap.value = rooms;
                deck.scheme = svgScheme;
                deckState.value = deck;
            }
        }
    };
    const getTariffOptions = () => {
        const result = [];
        for (let [tariff_id, tariff] of tariffListState.value.entries()) {
            result.push({
                text: tariff.label,
                html:
                    `<div class="tariff-option" title="${tariff.label}">
                        <span class="tariff-option__label">${tariff.label}</span>
                        <b class="tariff-option__available-seats">мест: ${tariff.availableSeats}</b>
                    </div>`,
                value: tariff_id,
                disabled: tariff.availableSeats <= 0,
            });
        }
        return result;
    };
    const getTariffById = (id) => tariffListState.value.get(id) ?? null;
    const getTariffPriceById = (id) => {
        const tariffInfo = tariffListState.value.get(id);
        let result = null;
        if (tariffInfo) {
            result = tariffInfo['price'];
        }
        return result;
    };
    const getOrderPrice = () => Array.from(passengerListState.value.values())
        .reduce((price, {tariff}) => price + (getTariffPriceById(tariff) ?? 0), 0);
    const getOrderPassengers = () => Array.from(passengerListState.value.values())
        .map(
            ({
                 lastname,
                 firstname,
                 middlename,
                 birthday,
                 doc,
                 birth_place,
                 sex,
                 tariff,
                 doc_series,
                 doc_number,
                 seat,
                 nationality
             }) => ({
                fullName: `${lastname} ${firstname} ${middlename}`,
                sex: sexStore.getById(sex)?.text,
                birthday: formatDate(birthday),
                birthplace: birth_place,
                citizen: nationalityStore.getByCode(nationality),
                documentType: documentStore.getById(doc)?.text,
                documentNumber: `${doc_series} ${doc_number}`,
                tariff: getTariffById(tariff)?.label,
                seatNumber: getSeatNumberById(seat),
                seatRow: getSeatRowById(seat),
            })
        );
    const getSeatById = (id) => seatMap.value.get(id) ?? null;
    const getSeatIdByNumber = (number) => {
        let result= null;
        for(const seat of seatMap.value.values()){
            if(seat['number'] === number){
                result = seat['id'];
                break;
            }
        }
        return result;
    };
    const getSeatNumberById = (id) => {
        const seatSrc = seatMap.value.get(id) ?? null;
        let result = null;
        if (seatSrc && seatSrc.number) {
            const [, column] = seatSrc.number.split('-');
            result = column;
        }
        return result;
    };
    const getSeatRowById = (id) => {
        const seatSrc = seatMap.value.get(id) ?? null;
        let result = null;
        if (seatSrc && seatSrc.number) {
            const [row] = seatSrc.number.split('-');
            result = row;
        }
        return result;
    };
    const getPassengerListFilled = () => Array.from(passengerListState.value.entries())
        .reduce(
            (result, [key, {tariff, seat, ...passengerSrc}]) => (
                result.set(key, {...passengerSrc, seat: getSeatById(seat), tariff: getTariffById(tariff)}),
                    result
            ),
            new Map()
        );
    const getPassengerListSeats = () => Array.from(passengerListState.value.entries())
        .reduce(
            (result, [key, {seat}]) => (result.set(key, seat), result),
            new Map()
        );
    const getSeatMapNumberIndexed = () => Array.from(seatMap.value).reduce(
        (res, [, seatInfo]) => (res.set(seatInfo['number'], seatInfo), res),
        new Map()
    );

    function splitTariffs() {
        let adultTariffs = [];
        let childTariffs = [];
        Array.from(passengerListState.value.values())
            .map(({seat, tariff}) => {
                const roomClass_id = getTariffById(tariff).roomClass_id;
                const metaTariffType = getTariffById(tariff).metaTariffType;
                if (passengerTypeStore.isChild(metaTariffType)) {
                    childTariffs.push({
                        cabin_id: seat,
                        tariff_id: tariff,
                        roomClass_id: roomClass_id,
                        isPassenger: false,
                    });
                } else {
                    adultTariffs.push({
                        cabin_id: seat,
                        tariff_id: tariff,
                        type: metaTariffType,
                        roomClass_id: roomClass_id,
                    });
                }
            });
        return {adultTariffs, childTariffs};
    }

    function competeTariffs(adultTariffs, childTariffs) {
        let tariffs = adultTariffs.map((
            {cabin_id: cabin_id_adult, tariff_id, type, roomClass_id: roomClass_id_adult}
        ) => {
            let tariff_ids = [tariff_id];
            if (passengerTypeStore.isAdult(type) && childTariffs.length > 0) {
                for (let key in childTariffs) {
                    const {cabin_id, tariff_id, roomClass_id} = childTariffs[key];
                    if (roomClass_id_adult === roomClass_id && !cabin_id) {
                        tariff_ids.push(tariff_id);
                        childTariffs[key] = {
                            cabin_id: cabin_id_adult,
                            tariff_id: tariff_id,
                            roomClass_id: roomClass_id,
                            isPassenger: false,
                        };
                        break;
                    }
                }
            }
            return {
                cabin_id: cabin_id_adult,
                tariff_ids: tariff_ids,
            }
        });
        return tariffs;
    }

    function getPassengersData(childTariffs, roomPlaceMap) {
        return Array.from(passengerListState.value.values()).map(
            ({
                 lastname,
                 firstname,
                 middlename,
                 birthday,
                 doc,
                 birth_place,
                 sex,
                 doc_series,
                 doc_number,
                 seat,
                 tariff,
                 nationality
             }) => {
                const passenger = {
                    first_name: firstname,
                    last_name: lastname,
                    sex,
                    nationality,
                    birth_date: formatDateIntoCRS(birthday),
                    phone: orderContacts.value.phone,
                    address: birth_place,
                    document_type: doc,
                    document_series: doc_series,
                    document_number: doc_number,
                    document_issue_date: "",
                    document_issuerer: ""
                };
                if (!middlename || middlename && middlename.trim().length === 0) {
                    passenger.without_patronymic = 1;
                } else {
                    passenger.patronymic_name = middlename;
                }
                let place_id;
                if (passengerTypeStore.isChild(getTariffById(tariff).metaTariffType)) {
                    const roomClass = getTariffById(tariff).roomClass_id;
                    for (let key in childTariffs) {
                        const {cabin_id, tariff_id, roomClass_id, isPassenger} = childTariffs[key];
                        if (tariff === tariff_id && roomClass === roomClass_id && !isPassenger) {
                            place_id = roomPlaceMap.get(cabin_id)[1];
                            childTariffs[key] = {
                                cabin_id: cabin_id,
                                tariff_id: tariff_id,
                                roomClass_id: roomClass_id,
                                isPassenger: true,
                            };
                            break;
                        }
                    }
                } else {
                    place_id = roomPlaceMap.get(seat)[0];
                }
                return {
                    place_id: place_id,
                    passenger
                };
            }
        );
    }

    const createCrsOrder = async () => {
        let acquiringUrl = null;
        let error = null;
        let {adultTariffs, childTariffs} = splitTariffs();
        let tariffs = competeTariffs(adultTariffs, childTariffs);

        let orderId = null;

        try {
            orderId = await orderPost(
                cruiseState.value.id,
                tariffs
            );
            const roomPlaceMap = await orderRoomPlacesGet(orderId);
            await passengersPut(
                orderId,
                getPassengersData(childTariffs, roomPlaceMap)
            );
            const {fullname, email, phone} = orderContacts.value;
            await ordersCustomerPatch(orderId, fullname, email, phone.replace('+', ''));
            await ordersFormalizeGet(orderId);
            const acquiringUrlResource = await acquiringGatewayPut(orderId);
            if (acquiringUrlResource !== null) {
                acquiringUrl = await acquiringUrlGet(acquiringUrlResource);
            }
        } catch (e) {
            console.error(e);
            error = e.message;
            if (error === 'Failed to fetch' || error.indexOf('NetworkError') >= 0) {
                error = 'Ошибка отправки запроса. Повторите попытку позже.'
            }
            if (orderId) {
                try {
                    await orderDelete(orderId);
                } catch (e) {
                    console.error(e);
                }
            }
        }
        return [acquiringUrl, error];
    };

    const getDirectionName = () => {
        const cruise = cruiseState.value;
        let result = null;
        if (cruise && cruise['destinations']) {
            result = cruise['destinations'][0] ? cruise['destinations'][0]['name'] : null;
        }
        return result;
    };

    const getMotorshipName = () => {
        const cruise = cruiseState.value;
        let result = null;
        if (cruise && cruise['motorship']) {
            result = cruise['motorship'] ? cruise['motorship'] : null;
        }
        return result;
    };

    const getMotorshipAlias = () => {
        const cruise = cruiseState.value;
        let result = null;
        if (cruise && cruise['motorship_alias']) {
            result = cruise['motorship_alias'] ? cruise['motorship_alias'] : null;
        }
        return result;
    };
    const saveOrder = (acquiringUrl) => {
        localStorage.setItem(
            'order', JSON.stringify({
                acquiringUrl,
                timestamp: (new Date()).getTime(),
                cruiseState: cruiseState.value,
                passengerListState: Array.from(passengerListState.value.entries()),
                orderContacts: orderContacts.value,
                price: getOrderPrice(),
            })
        );
    };
    const clearOrderRecord = () => {
        localStorage.removeItem('order');
    };
    const getSavedOrder = () => JSON.parse(localStorage.getItem('order'));

    return {
        useDate,
        setDate: (v) => {
            dateState.value = v
        },
        getDate: () => dateState.value,

        useDirection,
        setDirection: (v) => {
            directionState.value = v
        },
        getDirection: () => directionState.value,

        useCruise,
        setCruise: (v) => {
            cruiseState.value = v;
            for (const [, passenger] of passengerListState.value.entries()) {
                passenger.tariff = null;
                passenger.seat = null;
            }
        },
        getArrivalTime,
        getCruiseId: () => cruiseState.value?.id,
        getDirectionName,
        getMotorshipName,
        getMotorshipAlias,

        usePassengerCount,
        getPassengerCount: () => passengerCount.value,
        setPassengerCount: (v) => {
            passengerCount.value = v;
        },

        usePassengerList,
        getPassengerListFilled,
        getPassengerListSeats,
        setPassengerMap: (map) => {
            passengerListState.value = map;
        },
        updatePassengerList: (stateValue) => {
            const newList = new Map(stateValue.entries());
            const curList = new Map(passengerListState.value.entries());
            for (const [passengerKey, passenger] of curList.entries()) {
                if (newList.has(passengerKey)) {
                    const updatedPassenger = newList.get(passengerKey);
                    passenger['seat'] = passenger['tariff'] === updatedPassenger['tariff'] ? passenger['seat'] : null;
                    curList.set(passengerKey, Object.assign(passenger, updatedPassenger));
                    newList.delete(passengerKey)
                } else {
                    curList.delete(passengerKey);
                }
            }
            for (let [key, newPassenger] of newList.entries()) {
                curList.set(key, newPassenger)
            }
            passengerListState.value = curList;
        },
        setPassengersSeats: (passengerSeatMap) => {
            for (const [passengerKey, seatId] of passengerSeatMap.entries()) {
                const passenger = passengerListState.value.get(passengerKey);
                passenger['seat'] = seatId;
                passengerListState.value.set(passengerKey, passenger);
            }
        },
        setPassengerSeat: (passengerKey, passengerSeatNumber) => {
            const passenger = passengerListState.value.get(passengerKey);
            passenger['seat'] = getSeatIdByNumber(passengerSeatNumber);
            passengerListState.value.set(passengerKey, passenger);
        },

        useOrderContacts,
        setOrderContacts: (v) => {
            orderContacts.value = v;
        },

        useTariffList,
        getTariffOptions,
        getTariffById,
        getRoomClasses: () => roomClasses.value,
        downloadCruiseTariffs,
        getTariffPriceById,

        useDeck,
        getDeckSceheme: () => deckState.value.scheme,
        getSeatMapNumberIndexed,

        getOrderView: () => {
            const {fullname, email, phone} = orderContacts.value;
            const {date_starts_at_timestamp, date_end_at_timestamp} = cruiseState.value;

            return {
                price: getOrderPrice(),
                fullname,
                email,
                phoneNumber: phone,
                destination: getDirectionName(),
                motorship: getMotorshipName(),
                startDate: timestampToDate(date_starts_at_timestamp),
                startTime: timestampToTime(date_starts_at_timestamp),
                destTime: timestampToTime(date_end_at_timestamp),
                passengerList: getOrderPassengers(),
            };
        },
        createCrsOrder,
        getOrderPrice,
        getSavedOrder,
        saveOrder,
        clearOrderRecord
    };
};

export const orderStore = createOrderStore();
