import {apiRequest} from "@/shared/api.request";

//Сохраняет платежный шлюз в заказ, возвращает ресурс для получения ссылки на оплату заказа
export const acquiringGatewayPut = async (orderId) => {
    const data = await apiRequest(
        `/orders/${orderId}/payment-processings`,
        'PUT',
        {payment_processing_id: import.meta.env.VITE_PAYMENT_GATEWAY_ID}
    );
    return data && data['link'] ? data['link'] : null;
};
