import {apiRequest} from "@/shared/api.request";

export const orderRoomPlacesGet = async (orderId) => {
    const {data} = await apiRequest(`/orders/room?id=${orderId}`);
    const result = new Map();
    for(let cabin of data){
        const places = cabin['places'].length > 0 ? cabin['places'] : cabin['children'];
        if (cabin['infants'].length > 0) {
            places.push(...cabin['infants']);
        }
        result.set(cabin['room'], places);
    }
    return result;
};
