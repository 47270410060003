import {useSignal} from "@/shared/useSignal";
import {dateFromKey, dateToDateKey, timestampToDateKey, timestampToTime} from "@/shared/air-datepicker";
import {cruisesGet} from "@/api/cruises.get";
import {useActionModal} from "@/shared/ui/action-modal";
import {motorshipsStore} from "@/entity/ship/store/motorships.store";

const calendarFactory = (cruisesSrc) => {
    const defaultVal = new Map();
    return cruisesSrc.reduce(
        (res, current) => {
            if (current && current.date_starts_at_timestamp && current.destinations && current.destinations.length > 0) {
                const key = timestampToDateKey(current.date_starts_at_timestamp);
                current.motorship = motorshipsStore.getNameById(current.motorship_id);
                current.motorship_alias = motorshipsStore.getAliasById(current.motorship_id);
                if (res.has(key)) {
                    res.get(key).push(current);
                } else {
                    res.set(key, [current]);
                }
            }
            return res;
        },
        defaultVal
    );
};

export const createCruiseListStore = (list = []) => {
    const [state, effect] = useSignal(calendarFactory(list));
    const getDates = () => Array.from(state.value.keys());
    const getByDate = (date) => date ? state.value.get(dateToDateKey(date, false)) ?? [] : [];
    const getOptionsByDate = (date) => getByDate(date).map(
        ({id, name}) => ({
            text: name,
            value: id,
        })
    );
    const getDirectionById = (directionId) => Array.from(state.value.values()).reduce(
        (res, current) => {
            return res.concat(current.map(cruise => cruise.destinations).flat());
        },
        []
    ).find(({id}) => id === directionId);

    const getDirectionOptionsByDate = (date) => Array
        .from(
            getByDate(date).reduce(
                (result, {destinations}) => {
                    for (let destination of destinations) {
                        result.set(destination['id'], destination);
                    }
                    return result;
                }, new Map()
            ).values()
        ).map(({id, name}) => ({text: name, value: id,})
        );

    const getCruiseByDateAndDirection = (id, date) => getByDate(date)
        .filter(({destinations}) => {
            let result = false;
            for (let destination of destinations) {
                if (destination.id === id) {
                    result = true;
                    break;
                }
            }
            return result;
        });
    const getTimeOptionsByDestIdAndDate = (id, date) => getCruiseByDateAndDirection(id, date)
        .map(
            ({date_starts_at_timestamp, date_end_at_timestamp}) => ({
                text: timestampToTime(date_starts_at_timestamp),
                value: {departure: date_starts_at_timestamp, arrival: date_end_at_timestamp},
            })
        );

    const getCruiseByFilter = ({timeStamp, directionId, date}) =>
        getCruiseByDateAndDirection(directionId, date)
            .filter(({date_starts_at_timestamp}) => date_starts_at_timestamp === timeStamp);

    const downloadCruises = async () => {
        try {
            const cruises = await cruisesGet();
            state.value = calendarFactory(cruises);
        } catch (e) {
            console.error(e);
            useActionModal({
                heading: 'Ошибка сети',
                text: 'Не удалось загрузить круизы, пожалуйста повторите позже',
            });
        }
    };
    const isCurrentDateCruisesAvailable = () => !!state.value.get(dateToDateKey(new Date()));
    const getNearestDate = () => {
        const entries = Array.from(state.value.entries());
        return entries.length === 0 ? null : dateFromKey(entries[0][0]);
    };
    const cruisesExist = () => state.value.size > 0;

    return {
        isCurrentDateCruisesAvailable,
        getNearestDate,
        getTimeOptionsByDestIdAndDate,
        getDirectionOptionsByDate,
        getDirectionById,
        getDates,
        getByDate,
        getOptionsByDate,
        getCruiseByFilter,
        downloadCruises,
        cruisesExist,
        useEffect: effect,
    };
};

export const cruiseListStore = createCruiseListStore();
