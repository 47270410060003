const typeAdult = 'Adult';
const typeTeenager = 'Teenager';
const typeChild = 'Child';
const passengerTypeList = {
    '1': typeAdult,
    '2': typeTeenager,
    '3': typeChild,
};
const createPassengerTypeStore = () => {
    const getByType = (type) => {
        return passengerTypeList[type] ?? null;
    };

    return {
        options: Object
            .entries(passengerTypeList)
            .map(
                ([value, type]) => ({value, type})
            ),
        isAdult(type){
            return getByType(type) === typeAdult;
        },
        isChild(type){
            return getByType(type) === typeChild;
        },
    };
};
export const passengerTypeStore = createPassengerTypeStore();
