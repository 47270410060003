const createDocumentStore = () => {
    const options = [
        {value: 1, text: "Паспорт РФ"},
        {value: 4, text: "Заграничный паспорт"},
        {value: 5, text: "Свидетельство о рождении РФ"}
    ];
    return {
        options,
        getById:(id) => {
            const ID = parseInt(id);
            const res = options.filter((doc) => doc.value === ID);
            let result = null;
            if(res.length > 0){
                result = res[0];
            }
            return result;
        },
        isPassportRF: (value) => parseInt(value) === 1
    };
};
export const documentStore = createDocumentStore();
